@mixin highlighted-button {
  background: rgba(0, 0, 0, 0.2);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-weight: var(--font-weight-bold);
}

.channel-preview {
  background: transparent;
  border: none;
  cursor: pointer;

  &.selected {
    @include highlighted-button;
  }

  &:hover {
    @include highlighted-button;
  }
}

.channel-preview__item {
  font-size: 14px;
  color: white;
  position: relative;
}
.unread-count {
  background-color: #fb1a4e;
  color: #fff;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: -3px;
  position: absolute;
  right: -1px;
  strong {
    font-size: 12px;
    margin-top: -2px;
  }
}