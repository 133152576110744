.sidebar {
  box-shadow: inset 1px 0 0 var(--border-color);

  .sidebar__company-selection-bar {
    background: #2567c1;
    background-image: url("/assets/hazard-chat-sidebar.png");
    background-repeat: no-repeat;
    background-position: center calc(100% - 20px);
    background-size: 30px;
    box-shadow: 1px 0 0 rgba(0, 0, 0, 0.25);

    .sidebar__company-badge {
      background-color: #009feb;
      border-radius: 10px;
      transition: all 0.2s ease-in-out;
      border: 5px solid transparent;
      position: relative;
      border: 4px solid #009feb;
      svg {
        fill: #fff;
        path {
          fill: #fff;
        }
      }
      p {
        color: #fff;
      }
      &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.33);
        border: 4px solid #fff000;
        cursor: pointer;
      }
      &.selected {
        border: 4px solid #fff000;
        background-color: #fff;
        svg {
          fill: #3971be;
          path {
            fill: #3971be;
          }
        }
        p {
          color: #3971be;
        }
        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-left: 10px solid #fff000;
          border-right: 0;
          position: absolute;
          right: -12px;
        }
      }
    }
  }

  .channel-list-bar {
    background: #009feb;
    .channel-list-bar__header {
      background-color: #080f19;
      h4 {
        color: #fff;
      }
    }
    .channel-list-bar__header__text {
      font-weight: var(--font-weight-bold);
      font-size: 18px;
      line-height: 28px;
      color: white;
    }
  }
}
.date-time-picker-sidebar {
  margin: 10px;
}
.picker-container {
  border-radius: 5px;
  margin-top: 10px;
}



