@layer default-chat-sdk {
  @import '~stream-chat-react/dist/scss/v2/index.scss';
}

@import "global_theme_elements";
@import "global_theme_variables";
@import "layout";

@import "AdminPanel";
@import "Attachment";
@import "Channel";
@import "ChannelList";
@import "ChannelSearch";
@import "Message";
@import "MessageActions";
@import "MessageInput";
@import "MessageList";
@import "MessageReactions";
@import "PinnedMessageList";
@import "Sidebar";
@import "Thread";
@import "TypingIndicator";
@import "UserList";
@import "WorkspaceHeader";

*::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

*::-webkit-scrollbar-thumb {
  background-color: #b2b1b5;
  border-radius: 2px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}


.table-container {
  padding: 20px;
}
.table-block {
  margin-bottom: 20px;
}

.session-title-row {
  display: flex;
  align-items: center;
}

.media-session-info {
  display: flex;
  align-items: center;
}
.missing-media-server {
  p {
    color: #e01a5a;
  }
}
.has-recordings-row {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.recording-cell {
  display: flex;
  align-items: center;
}
.warning-alert {
  display: flex;
  background-color: #120901;
  margin: 10px;
  border-radius: 5px;
  border: 2px solid #401c05;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  p {
    display: flex;
    span {
      color: #e48800;
      font-weight: bold;
      width: 100%;
      font-size: 15px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }
  }
  i {
    margin: 10px;
    font-size: 10px;
    color: #ead7b7;
    display: flex;
    max-width: 250px;
    align-items: center;
    display: flex;
  }
}

.media-server-container {
  padding: 10px;
}
.no-sessions {
  opacity: 0.3;
}
.action-top-bar {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.healthy-media-server {
  padding: 10px;
  border: 2px solid #30e230;
  border-radius: 5px;
  margin: 5px 10px;
  display: flex;
  background-color: #12270e;
  p {
    color: #30e230;
  }
  svg {
    color: #30e230;
    margin-right: 10px;
  }
}

.unhealthy-media-server {
  padding: 10px;
  border-bottom: 5px dotted #dfff06;
  margin: 5px 10px;
  display: flex;
  p {
    color: #fff;
    span {
      color: #dfff06;
      font-weight: bolder;
    }
  }
  svg {
    color: #dfff06;
    margin-right: 10px;
  }
}
.api-server-header {
  padding: 10px;
  border-bottom: 5px dotted #0662ff;
  margin: 5px 10px 10px 10px;
  display: flex;
  p {
    color: #fff;
  }
  svg {
    color: #0662ff;
    margin-right: 10px;
  }
}
.login-page-container {
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  background-image: url("/assets/hazard-chat.png");
  background-repeat: no-repeat;
  background-size: 70px;
  background-position: center 10px;
  padding-top: 100px;
  border-radius: 10px;

}
.login-screen-wrapper {
  background-color: #2567c1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.login-btn-wrap > button > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-left: 5px;
    font-weight: bolder;
  }
}