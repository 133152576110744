.sidebar {
  display: flex;
  height: 100%;

  .sidebar__company-selection-bar {
    width: 72px;
    padding: 10px;
    padding-top: 70px;

    .sidebar__company-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 55px;
      height: 55px;
      padding: 5px;
      margin-left: -2px;
      padding-top: 0;
      svg {
        width: 28px;
        margin-top: -5px;
      }
      &:not(:first-child) {
        margin-top: 10px;
      }
      p {
        position: absolute;
        font-size: 8px;
        font-weight: bolder;
        white-space: nowrap;
        bottom: -5px;
      }
    }
  }

  .channel-list-bar {
    display: flex;
    flex-direction: column;
    width: 240px;

    .channel-list-bar__header {
      padding: 16px;
      padding-right: 0;
      display: flex;
      height: var(--header-height);
      left: -73px;
      width: calc(100% + 73px);
      position: relative;
    }
    .channel-list-user-info {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    .more-button-container {
      display: flex;
    }
  }
}
.custom-avatar {
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 50px;
  background-size: cover;
  border: 2px solid #497abb;
  margin-left: -5px;
  margin-right: 15px;
}
.user-company-info {
  color: #bbe8fe;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 10px;
}
.user-info-stack {
  display: flex;
  flex-direction: column;
  width: 100%;
  .channel-list-user-name {
    margin: 0;
  }
}
div.date-picker-static {
  transform: scale(0.75);
  margin-left: -50px;
  margin-top: -62px;
  background-color: #102737;
  max-height: 335px;
}

